<template>
  <div>
    <PageHeader :title="title" :items="item" />
    <div class="row">
      <div class="col-md-8">
        <div class="card-body">
          <!-- Project selection card -->
          <div class="card shadow-sm">
            <div
              class="custom-notifications d-flex justify-content-between align-items-center flex-wrap"
            >
              <div class="custom-title">
                Add details to proceed with DocPublisher
              </div>
              <div></div>
            </div>
            <div class="container">
              <form>
                <div class="form-group row">
                  <label class="col-md-12 col-form-label"
                    >Select Project<span class="text-danger">*</span></label
                  >
                  <div class="col-md-12">
                    <multiselect
                      style="width: 100%"
                      v-model="selectedproject"
                      :options="projectList.map((item) => item.projectName)"
                      :max-height="150"
                      placeholder="Choose a Project"
                      class="custom-multiselect"
                      :allow-empty="false"
                    >
                    </multiselect>
                  </div>
                </div>
                <div v-if="hasData" class="form-group row">
                  <label class="col-md-12 col-form-label"
                    >Select Branch<span class="text-danger">*</span></label
                  >
                  <div class="col-md-12">
                    <multiselect
                      :max-height="150"
                      v-model="selectedBranch"
                      :options="repobranchesdata.map((item) => item.text)"
                      placeholder="Choose a branch"
                      :allow-empty="false"
                    >
                    </multiselect>
                  </div>
                </div>
                <div class="row from-group">
                  <div class="col-md-12">
                    <button
                      type="submit"
                      class="btn btn-primary mb-3 btn-sm"
                      :disabled="isButtonDisabled"
                      @click.prevent="
                        selectedproject !== '' &&
                          setdata(selectedProjectOwner, selectedProjectName)
                      "
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Multiselect from "vue-multiselect";
import checkurl from "../../../components/urlvalidator";
import { eventBus } from "../../../main";
import PageHeader from "@/components/pageheader";
import CryptoJS from "crypto-js";
import { secretKey } from "../../../api/global.env";
import githubService from "../../../services/github";
import { decryptParam } from "../../../services/cryptoFunction";
export default {
  components: {
    PageHeader,
    Multiselect,
  },
  data() {
    return {
      selectedproject: "",
      userId: this.$store.state.Auth.userId,
      items: "",
      selectedProjectOwner: "",
      selectedProjectName: null,
      selectedBranch: "",
      previousSelectedBranch: "",
      projectList: [],
      repobranchesdata: [],
      title: "DocPublisher",
      item: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "DocPublisher",
          active: true,
        },
      ],
      ignoreNextChange: false, // Flag to ignore next change
    };
  },
  validateURL() {
    const newRepoUser = CryptoJS.AES.decrypt(
      this.$route.params.repouser,
      secretKey
    ).toString(CryptoJS.enc.Utf8);
    const newRepoName = CryptoJS.AES.decrypt(
      this.$route.params.reponame,
      secretKey
    ).toString(CryptoJS.enc.Utf8);
    const oldRepoUser = localStorage.getItem("repouser");
    const oldRepoName = localStorage.getItem("reponame");
    if (newRepoName !== oldRepoName || newRepoUser !== oldRepoUser) {
      checkurl(newRepoName);
    }
  },
  created() {
    eventBus.$emit("update-sidebar", "menuitems.docpublisher.text");
    if (this.$store.state.Auth.projectsData.length) {
      this.projectList = this.$store.state.Auth.projectsData;
      this.initialSelectProjectIfQueryPresent();
    } else {
      this.getProjectData();
    }
  },
  computed: {
    isButtonDisabled() {
      return this.selectedProjectName === null || this.selectedBranch === "";
    },
    hasData() {
      return this.repobranchesdata.length > 0;
    },
  },
  methods: {
    ...mapActions({
      get: "userProjectDetails",
    }),
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    setdata(owner, projectName) {
      this.redirectProject(owner, projectName);
    },
    getProjectData() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      this.$store.getters.client
        .get(`/projectuser/byuserid?userId=${this.userId}`)
        .then((response) => {
          loader.hide();
          if (Array.isArray(response.data)) {
            this.projectList = response.data;
            // Commit the complete project data to the Vuex store

            this.$store.commit("setProjectsList", response.data);
            this.initialSelectProjectIfQueryPresent();
          } else {
            // Handle the case where the response data is not an array
            this.messageToast(
              "Error",
              "danger",
              "Received invalid project data from the server"
            );
          }
        })
        .catch((error) => {
          loader.hide();
          // Handle the error by showing a message or performing other error handling actions
          this.messageToast(
            "Error",
            "danger",
            error.response
              ? error.response.data.message
              : "An error occurred while fetching project data."
          );
        });
    },
    async redirectProject(repouser, reponame) {
      const encryptedRepouser = CryptoJS.AES.encrypt(
        repouser,
        secretKey
      ).toString();
      const encryptedReponame = CryptoJS.AES.encrypt(
        reponame,
        secretKey
      ).toString();
      const encryptedBranch = CryptoJS.AES.encrypt(
        this.selectedBranch,
        secretKey
      ).toString();
      const encryptedOwner = CryptoJS.AES.encrypt(
        this.selectedProjectOwner,
        secretKey
      ).toString();

      localStorage.setItem("repouser", encryptedRepouser);
      localStorage.setItem("reponame", encryptedReponame);
      const encodedRepouser = encodeURIComponent(encryptedRepouser);
      const encodedReponame = encodeURIComponent(encryptedReponame);
      const encodedBranch = encodeURIComponent(encryptedBranch);
      const encodedOwner = encodeURIComponent(encryptedOwner);
      this.$router.push({
        path: `/docpublisher/${encodedRepouser}/${encodedReponame}/${encodedBranch}/${encodedOwner}`,
      });
    },
    async getRepoBranch() {
      let loader = this.$loading.show({
        loader: "dots",
      });

      this.$store.getters.client
        .get(
          `orguser/repobranches?repoUser=${this.selectedProjectOwner}&repoName=${this.selectedProjectName}`
        )
        .then(async (response) => {
          if (this.$route.query.branchName) {
            this.selectedBranch = decryptParam(this.$route.query.branchName);
            this.previousSelectedBranch = this.selectedBranch;
            this.checkoutBranch(this.selectedBranch);
            this.$router.push({});
          } else {
            const res = await githubService.currentBranch(
              this.selectedProjectName
            );
            this.selectedBranch = res.data;
            this.previousSelectedBranch = res.data;
          }
          if (response.data && response.data.length > 0) {
            this.repobranchesdata = response.data.map((element) => ({
              value: element.name,
              text: element.name,
            }));
          } else {
            // Handle the case when the response is empty or does not contain branches.
            this.messageToast(
              "invalid request",
              "danger",
              "No branches found in the Project."
            );
          }
        })
        .catch((error) => {
          // Handle errors here
          this.messageToast(
            "invalid request",
            "danger",
            error.response.data.message
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    async checkoutBranch(brachName) {
      this.selectedBranch = brachName;
      console.log("in checkoutBranch: ", this.selectedBranch);
      // ============checkout the branch============
      const res = await githubService.checkout(
        {
          repoName: this.selectedProjectName,
          branchName: this.selectedBranch,
        },
        this
      );
      console.log("ckeckout res: ", res);

      if (res === false) {
        this.messageToast(
          "Invalid request",
          "danger",
          "Failed to checkout branch."
        );
        this.selectedBranch = this.previousSelectedBranch;
        this.ignoreNextChange = true; // Set the flag to ignore next change
        return;
      } else {
        this.previousSelectedBranch = this.selectedBranch;
        this.messageToast("Branch checkout", "success", res.message);
      }
      // ============checkout the branch============
    },
    initialSelectProjectIfQueryPresent() {
      const query = this.$route.query;
      if (query.projectName && query.branchName) {
        this.selectedproject = decryptParam(query.projectName);
        this.selectedBranch = decryptParam(query.branchName);
      } else if (this.$store.state.Auth.currentWorkingProject) {
        const isPresent = this.projectList.some(
          (item) =>
            item.projectName ===
            this.$store.state.Auth.currentWorkingProject.projectName
        );
        if (!isPresent) {
          this.$store.commit("setCurrentWorkingProject", null);
          localStorage.removeItem("currentWorkingProject");
          return;
        }
        this.selectedproject =
          this.$store.state.Auth.currentWorkingProject.projectName;
      }
    },
  },
  watch: {
    selectedproject(newVal) {
      const selectedProject = this.projectList.find(
        (item) => item.projectName === newVal
      );
      console.log("selected project: ", selectedProject);
      this.selectedProjectOwner = selectedProject.owner;
      this.selectedProjectName = selectedProject.projectName;
      this.repobranchesdata = [];
      this.getRepoBranch();
      this.$store.commit("setCurrentWorkingProject", selectedProject);
    },
    selectedBranch(newVal, oldValue) {
      // Ignore the next change if we're reverting the value
      if (this.ignoreNextChange) {
        this.ignoreNextChange = false; // Reset the flag
        return;
      }
      if (oldValue && newVal) {
        console.log("in selectedBranch: ", newVal, oldValue);
        this.checkoutBranch(newVal);
      }
    },
  },
};
</script>

<style scoped>
label {
  font-weight: 400;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.42px;
  letter-spacing: 0em;
  text-align: left;
  color: #17233d;
}

.custom-notifications {
  padding: 14px;
  gap: 24px;
}

.custom-title {
  font-size: 16 px;
  font-weight: 400;
  line-height: 18.77px;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
</style>
