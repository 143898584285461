import store from "../state/store";
const downloadSourceFiles = async ({ repoName, commitSHA = "" }) => {
  console.log("in downloadSourceFiles: ", repoName, commitSHA);
  const api = store.getters.client;

  await api
    .get(
      `/orguser/docpublish/download-output?repoName=${repoName}&commitSHA=${commitSHA}`,
      {
        responseType: "blob",
      }
    )
    .then((response) => {
      console.log("download res: ", response);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", repoName + ".zip");
      document.body.appendChild(link);
      link.click();
    })
    .catch(async (error) => {
      let errorText = null;

      if (error.response.data instanceof Blob) {
        const reader = new FileReader();

        // Return a new Promise to handle the async flow
        await new Promise((resolve, reject) => {
          reader.onload = () => {
            errorText = reader.result
              ? reader.result
              : "Error in the downloading file.";
            console.log("📢[tree.vue:203]: errorText: ", errorText);

            try {
              // Attempt to parse the error message if it's JSON formatted
              const parsedError = JSON.parse(errorText);
              errorText = parsedError.message || "An unknown error occurred";
              console.log(
                "📢[download-source-files.js:35]: errorText: ",
                errorText
              );
              resolve(); // Resolve the Promise once we're done
            } catch (parseError) {
              // Handle parsing errors
              errorText = "Error parsing the error message.";
              reject(parseError); // Reject if there was a parsing issue
            }
          };

          reader.onerror = (err) => {
            reject(err); // Reject the Promise if FileReader encounters an error
          };

          reader.readAsText(error.response.data); // Start reading the Blob as text
        });
      }

      // If errorText is still null, rethrow the original error
      if (errorText === null) {
        throw error; // Rethrow the original error
      } else {
        throw new Error(errorText); // Throw a new error with the processed message
      }
    });
};

export default downloadSourceFiles;
