<template>
  <div class="align-items-stretch row">
    <div
      class="col-12 col-sm-4 mb-3"
      v-for="(data, index) in cardData"
      :key="index"
    >
      <router-link :to="data.link">
        <div class="card mb-0 h-100">
          <div class="card-body">
            <div class="custom-title mb-0">{{ data.name }}</div>
            <div class="d-flex align-items-start justify-content-between mt-3">
              <span
                class="mdi d-flex align-items-center justify-content-center"
                :class="data.icon"
                :style="{
                  color: data.color,
                  fontSize: '25px',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor: `${data.color}42`,
                }"
              ></span>
              <h2 class="mb-0" style="font-size: 30px; font-weight: 300">
                {{ formatNumber(data.value) }}
              </h2>
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div class="col-12 col-sm-4 mb-3">
      <router-link to="/usage">
        <div class="card mb-0 h-100">
          <div class="card-body">
            <div class="custom-title mb-0">Credits Usage</div>
            <div class="d-flex align-items-start justify-content-between mt-3">
              <!-- <span
                class="mdi d-flex align-items-center justify-content-center mdi-file-export custom-class"
                :style="{
                  color: '#fb9678',
                  fontSize: '25px',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundColor: `#fb967842`,
                }"
              ></span> -->
              <div>
                <p class="mb-0" style="font-size: 14px; font-weight: 300">
                  <b>Used: </b>
                  {{ formatNumber(creditsDetails?.totalCreditsUsed) }}
                </p>
                <p
                  class="mb-0"
                  style="font-size: 14px; font-weight: 300"
                  v-if="creditsDetails.remainingCredits >= 0"
                >
                  <b>Remaining: </b>
                  {{ formatNumber(creditsDetails.remainingCredits) }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    if (this.$store.state.Auth.projectsData.length) {
      this.projectList = this.$store.state.Auth.projectsData;
      this.cardData[0].value = this.projectList.length;
    } else {
      this.getProjectData();
    }

    if (this.$store.state.Auth.recentPublicationsData.length) {
      this.releases = this.$store.state.Auth.recentPublicationsData;
      this.cardData[1].value = this.releases.length;
    } else {
      this.getReleaseNote(); // Fetch recent publications
    }
    // if (this.$store.state.Auth.creditsDetails) {
    //   this.creditsDetails = this.$store.state.Auth.creditsDetails;
    // } else {
    this.getCreditsDetails(); // Fetch recent publications
    // }

    // this.getPublisherTypes();
    // this.getMigrationsOptions();
  },
  data() {
    return {
      userId: this.$store.state.Auth.userId,
      projectList: [],
      releases: [],
      publisherTypes: [],
      migrationsOptions: [],
      creditsDetails: null,
      cardData: [
        {
          name: "Projects",
          icon: "mdi-file-document-edit-outline",
          value: 0,
          link: "/docmanager",
          color: "#03a9f3",
        },
        {
          name: "Releases",
          icon: "mdi-folder-multiple count-icon-cust-cls",
          value: 0,
          link: "/release",
          color: "#ab8ce4",
        },
        // {
        //   name: "Credits Usage",
        //   icon: "mdi-file-export custom-class",
        //   value: 0,
        //   link: "/usage",
        //   // link: "/docpublisher",
        //   color: "#fb9678",
        // },
        // {
        //   name: "Migration Types",
        //   icon: "mdi-file-replace",
        //   value: 0,
        //   link: "/docmigration",
        //   color: "#00c292",
        // },
      ],
    };
  },
  methods: {
    formatNumber(number) {
      return String(number).padStart(2, "0"); // Pads single digit numbers with leading zero
    },
    async getProjectData() {
      this.$store.getters.client
        .get(`/projectuser/byuserid?userId=${this.userId}&show=hide`)
        .then((response) => {
          if (response.data) {
            this.projectList = response.data;
            this.cardData[0].value = this.projectList.length;
            this.$store.commit("setProjectsData", response.data);
          }
        })
        .catch(() => {});
    },
    getReleaseNote() {
      this.$store.getters.client
        .get(`/orguser/release/byuserId?userId=${this.userId}`)
        .then((response) => {
          if (response.data) {
            this.releases = response.data;
            this.$store.commit("setRecentPublicationsData", response.data);
            this.cardData[1].value = this.releases.length;
          }
        })
        .catch(() => {});
    },
    async getoutputFormat() {
      try {
        const res = await this.$store.getters.client.get("/plugins");
        return res.data;
      } catch (error) {
        return [];
      }
    },
    async getCustomOutputFormat() {
      try {
        const res = await this.$store.getters.client.get(
          "/orguser/docpublisher/customplugin"
        );
        return res.data;
      } catch (error) {
        console.error("Error fetching custom output format:", error);
        return null;
      }
    },

    async getPublisherTypes() {
      const defaultPublisherTypes = await this.getoutputFormat();
      const customPublisherTypes = await this.getCustomOutputFormat();
      this.publisherTypes = [...defaultPublisherTypes, ...customPublisherTypes];

      this.cardData[2].value = this.publisherTypes.length;
    },
    async getMigrationsOptions() {
      const response = await this.$store.getters.client.get(
        `/orguser/docMigration/get-docMigrationType-available `
      );
      this.migrationsOptions = response.data.data;
      console.log(
        "📢[InfoCard.vue:140]: migrationsOptions: ",
        this.migrationsOptions
      );
      this.cardData[3].value = this.migrationsOptions.length;
    },

    getCreditsDetails() {
      this.$store.getters.client
        .get(`/orguser/credits-info`)
        .then((response) => {
          this.creditsDetails = response.data;
          // this.$store.commit("setCreditsDetails", response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.custom-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.5px;
  text-align: left;
  color: rgba(23, 35, 61, 1);
}
</style>
