<template>
  <b-modal ref="deployToChatbotModal" hide-footer>
    <div>
      <div class="my-3" v-if="newOrExistingBranch == 'new'">
        <label>New Branch Name<span class="text-danger">*</span></label>
        <input class="form-control" id="newBranchName" v-model="selectedName" />
      </div>
      <div class="my-3" v-if="newOrExistingBranch == 'existing'">
        <label>Select Branch Name<span class="text-danger">*</span></label>

        <!-- Multiselect for project selection -->
        <multiselect
          v-model="selectedName"
          :options="repobranchesdata"
          placeholder="Choose a branch"
          :max-height="150"
        >
        </multiselect>
      </div>
      <div class="text-center">
        <!-- @click="createNewBranchHandler" -->
        <button
          class="btn btn-primary"
          :disabled="!selectedName"
          @click="deployToChatbotHandler()"
        >
          Deploy to chatbot
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";

export default {
  components: {
    Multiselect,
  },

  props: { userActivityId: String, repouser: String },
  data() {
    return {
      selectedName: null,
      newOrExistingBranch: null,
      repobranchesdata: [],
    };
  },
  methods: {
    messageToast(messageToastTitle, messageToastVariant, messageToastContent) {
      this.$bvToast.toast(messageToastContent, {
        title: messageToastTitle,
        variant: messageToastVariant,
        solid: true,
      });
    },
    deployToChatbotSelectBranch() {
      console.log(this.userActivityId);

      Swal.fire({
        title: "Choose Existing or New Branch?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "New",
        cancelButtonText: "Extisting",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.selectedName = null;
          console.log("confirm new");
          this.newOrExistingBranch = "new";
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancle existing");
          await this.getRepoBranch();
          this.newOrExistingBranch = "existing";
        }
        this.$refs["deployToChatbotModal"].show();
      });
    },
    async getRepoBranch() {
      let loader = this.$loading.show({
        loader: "dots",
      });

      this.$store.getters.client
        .get(
          `orguser/repobranches?repoUser=${this.repouser}&repoName=AI-Deployment`
        )
        .then(async (branchResponse) => {
          if (branchResponse.data && branchResponse.data.length > 0) {
            this.repobranchesdata = branchResponse.data
              .map((element) => element.name)
              .filter((f) => f !== "main");

            if (this.repobranchesdata.length == 0) {
              this.messageToast(
                "No branch found",
                "danger",
                "No branch found in the repository."
              );
            }
          } else {
            this.messageToast(
              "No branch found",
              "danger",
              "No branch found in the repository."
            );
          }
        })
        .catch((error) => {
          this.messageToast(
            "invalid request",
            "danger",
            error.response.data.message
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
    async deployToChatbotHandler() {
      let loader = this.$loading.show({
        loader: "dots",
      });
      const deployToChatbotParams = {
        selectedName: this.selectedName,
        publishOption: this.newOrExistingBranch,
        userActivityId: this.userActivityId,
      };
      await this.$store.getters.client
        .post(`/orguser/workspace/publish-to-chatbot`, deployToChatbotParams)
        .then((res) => {
          console.log("📢[generateoutput.vue:684]: res: ", res);
          this.messageToast("Success", "primary", res.data.message);
          this.$refs["deployToChatbotModal"].hide();
          this.$emit("deleteUserActivityId");
        })
        .catch((err) => {
          this.messageToast(
            "Invalid request",
            "danger",
            err.response.data.message
          );
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
};
</script>
